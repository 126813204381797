import React, { Component } from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import {
  InstantSearch,
  Configure,
  Index,
  Highlight,
  // SearchBox,
} from 'react-instantsearch/dom';
import { connectAutoComplete } from 'react-instantsearch/connectors';
import Autosuggest from 'react-autosuggest';
import { view } from 'react-easy-state';
import emojis from 'emojis';

import main from '../stores';

import theme from '../styles/autosuggest.module.css';

@connectAutoComplete
@view
class AutoComplete extends Component {
  static propTypes = {
    hits: PropTypes.arrayOf(PropTypes.object).isRequired,
    // currentRefinement: PropTypes.string.isRequired,
    refine: PropTypes.func.isRequired,
  };

  // componentDidMount() {
  //   // main.searchKeyword = this.props.currentRefinement;
  //   // this.props.refine(main.searchKeyword);
  // }

  onChange = (event, { newValue }) => {
    main.searchKeyword = newValue;
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.props.refine(value);
  };

  onSuggestionsClearRequested = () => {
    this.props.refine();
  };

  getSuggestionValue(hit) {
    return hit.title;
  }

  renderSuggestion(hit) {
    return (
      <div>
        <div className={theme.suggestionCategory}>
          <Highlight attribute="categoryTitle" hit={hit} tagName="mark" />
          <span css={tw`text-brand-dark`}>
            <i className="far fa-star" /> {hit.stars}
          </span>
        </div>
        {hit.parentTitle && (
          <>
            <Highlight attribute="parentTitle" hit={hit} tagName="mark" />{' '}
            <i className="far fa-chevron-right" css={tw`opacity-50 text-xs`} />{' '}
          </>
        )}

        <Highlight attribute="title" hit={hit} tagName="mark" css={tw`font-bold`} />
        {/* <Highlight attribute="description" hit={hit} tagName="mark" css={tw`text-xs mt-1 leading-none block`} /> */}
        <p css={tw`text-xs mt-1 leading-none block`}>{emojis.unicode(hit.description)}</p>

        <div className={theme.suggestionCategory} css={[tw`opacity-50 block mt-2`]}>
          curated by <Highlight attribute="owner" hit={hit} tagName="mark" css={tw`font-black`} />
        </div>
      </div>
    );
  }

  suggestionSelected(ev, { suggestion, suggestionValue }) {
    navigate(`/${suggestion.slug}`);
  }

  render() {
    const { hits } = this.props;

    const inputProps = {
      placeholder: 'Search for something...',
      onChange: this.onChange,
      value: main.searchKeyword,
      type: 'search',
    };

    return (
      <Autosuggest
        theme={theme}
        suggestions={hits}
        // alwaysRenderSuggestions
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        onSuggestionSelected={this.suggestionSelected}
        inputProps={inputProps}
      />
    );
  }
}

const Search = () => (
  <InstantSearch
    appId="ZIGZM2EI1S"
    apiKey="e5bde2f6135373e84779effde2dfb671"
    indexName="repo"
  >
    <AutoComplete />
    <Configure hitsPerPage={6} />
    <Index indexName="repo" />
  </InstantSearch>
);

export default Search;
