import { store } from 'react-easy-state';

const main = store({
  masonry: null,
  masonryCurators: null,
  sortFields: [
    { field: 'default', order: 'asc', label: 'default' },
    { field: 'title', order: 'asc', label: 'title' },
    { field: 'pushedAt', order: 'desc', label: 'last update' },
    { field: 'stars', order: 'desc', label: 'stars' },
  ],
  currentSort: 0,
  showSummary: true,
  showInfo: true,
  searchKeyword: '',

  toggleSort() {
    main.currentSort = (main.currentSort + 1) % main.sortFields.length;
  },

  get sortField() {
    return main.sortFields[main.currentSort];
  },

  toggleSummary() {
    main.showSummary = !main.showSummary;
  },

  toggleInfo() {
    main.showInfo = !main.showInfo;
  },

});

export default main;
