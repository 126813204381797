import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { view } from 'react-easy-state';
import { Location } from '@reach/router';
import _ from 'lodash';

// import Logo from '../images/svgs/logo-bnw.svg';
import main from '../stores';
// import { colors } from '../../tailwind';
import AnchorLink from './anchor-link';
import Search from './search';

const Container = styled.div`
  ${tw`px-4 pt-8 pb-4 font-sans z-50 relative`};
`;
const Title = styled.h1`
  ${tw`text-grey-darkest text-5xl font-black m-0 mt-4 tracking-tight`};
`;
const StyledLink = styled(Link)`
  ${tw`no-underline text-inherit hover:no-underline hover:text-black`};
`;

const OptionButton = styled.button`
  ${tw`
    bg-brand-lightest
    hover:bg-brand
    text-brand-dark
    hover:text-white
    font-black
    uppercase
    tracking-wide
    py-1
    px-2
    mr-1
    rounded
  `}
  font-size: 0.7rem;
`;

const Options = view(() => (
  <span css={tw`inline-flex mb-2`}>
    <OptionButton type="button" onClick={main.toggleSort}>
      Sorted by {main.sortField.label}
    </OptionButton>
    <OptionButton type="button" onClick={main.toggleSummary}>
      {main.showSummary ? 'Hide' : 'Show'} summary
    </OptionButton>
    <OptionButton type="button" onClick={main.toggleInfo}>
      {main.showInfo ? 'Hide' : 'Show'} details
    </OptionButton>
  </span>
));

const CategoryList = view(({ data }) => {
  const categories = _.map(data.allAweCategory.edges, 'node');

  return (
    <nav>
      <ul css={[tw`list-reset flex flex-wrap`, { margin: '0 -0.5rem', marginTop: '1rem' }]}>
        {categories.map(({ slug, title }) => (
          <li key={slug} css={tw`py-1 px-2 text-sm`}>
            <AnchorLink href={`#${slug}`} offsetTop={30}>{title}</AnchorLink>
          </li>
        ))}
      </ul>
    </nav>
  );
});

const SearchBox = () => (
  <div
    css={tw`w-full md:w-1/3 mb-2 md:mb-0`}
  >
    <Search />
  </div>
);

export default
@view
class Header extends React.Component {
  render() {
    const { data, pageHeader } = this.props;

    const randomSlug =
      data.allAweRepo.edges[Math.floor(Math.random() * data.allAweRepo.edges.length)].node.slug;

    return (
      <Container className="container">
        {/* <Logo
          css={{
            height: '16rem',
            opacity: 0.4,
            position: 'absolute',
            top: -10,
            right: -60,
            zIndex: -1,
            transform: 'rotate(10deg)',
            '.st0, .st1': {
              fill: colors['grey-light']
            }
          }}
        /> */}

        <div css={tw`flex flex-wrap justify-between items-center`}>
          <Title>
            <StyledLink to="/">
              Awesome<span css={tw`text-brand`}>List</span>
            </StyledLink>
          </Title>

          <SearchBox />
        </div>

        <div css={tw`text-sm font-thin tracking-normal text-grey-dark leading-tight items-center mb-1`}>
          <span css={tw`text-brand font-black`}>{data.allAweRepo.totalCount}</span> awesome lists.{' '}
          <span css={tw`text-brand font-black`}>{data.allAweCategory.totalCount}</span> categories.{' '}
          <Link to="/curators"><span css={tw`text-brand font-black`}>{data.allOwnersJson.totalCount}</span> curators.</Link>{' '}
          <Link to={`/${randomSlug}`}>I feel lucky.</Link>
        </div>

        <Location>
          {({ location }) => location.pathname === '/' && (
            <>
              <Options />
              <CategoryList data={data} />
            </>
          )}
        </Location>

        {pageHeader}
      </Container>
    );
  }
}
