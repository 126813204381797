import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { Location } from '@reach/router';
import scrollTo from '@degjs/scroll-to';

function offset(el) {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}

const AnchorLink = ({ href, offsetTop = 16, ...props }) => (
  <Location>
    {({ location }) => (
      <a // eslint-disable-line
        onClick={e => {
          if (/^#/.test(href)) {
            e.preventDefault();
            scrollTo({
              position: offset(document.getElementById(href.replace(/^#/, ''))).top - offsetTop,
              duration: 300,
            });
            navigate(`${location.pathname}${href}`);
          }
        }}
        {...props}
        href={href}
      />
    )}
  </Location>
);

AnchorLink.propTypes = {
  href: PropTypes.string.isRequired,
  offsetTop: PropTypes.number,
};

export default AnchorLink;
